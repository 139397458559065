@font-face {
  font-family: "times32";
  src: url("TIMES.TTF-32-Regular.woff2") format("woff2"), url("TIMES.TTF-32-Regular.woff") format("woff");
  font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "times32";
  src: url("TIMESBD.TTF-32-Bold.woff2") format("woff2"), url("TIMESBD.TTF-32-Bold.woff") format("woff");
  font-weight: bold;
font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "times32";
  src: url("TIMESBI.TTF-32-BoldItalic.woff2") format("woff2"), url("TIMESBI.TTF-32-BoldItalic.woff") format("woff");
  font-weight: bold;
font-style: italic;
font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "times32";
  src: url("TIMESI.TTF-32-Italic.woff2") format("woff2"), url("TIMESI.TTF-32-Italic.woff") format("woff");
  font-style: italic;
font-display: block;
  text-rendering: geometricPrecision;
}