@font-face {
  font-family: "times24";
  src: url("TIMES.TTF-24-Regular.woff2") format("woff2"), url("TIMES.TTF-24-Regular.woff") format("woff");
  font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "times24";
  src: url("TIMESBD.TTF-24-Bold.woff2") format("woff2"), url("TIMESBD.TTF-24-Bold.woff") format("woff");
  font-weight: bold;
font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "times24";
  src: url("TIMESBI.TTF-24-BoldItalic.woff2") format("woff2"), url("TIMESBI.TTF-24-BoldItalic.woff") format("woff");
  font-weight: bold;
font-style: italic;
font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "times24";
  src: url("TIMESI.TTF-24-Italic.woff2") format("woff2"), url("TIMESI.TTF-24-Italic.woff") format("woff");
  font-style: italic;
font-display: block;
  text-rendering: geometricPrecision;
}