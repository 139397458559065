@font-face {
  font-family: "arial16";
  src: url("ARIAL.TTF-16-Regular.woff2") format("woff2"), url("ARIAL.TTF-16-Regular.woff") format("woff");
  font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "arial16";
  src: url("ARIALBD.TTF-16-Bold.woff2") format("woff2"), url("ARIALBD.TTF-16-Bold.woff") format("woff");
  font-weight: bold;
font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "arial16";
  src: url("ARIALBI.TTF-16-BoldItalic.woff2") format("woff2"), url("ARIALBI.TTF-16-BoldItalic.woff") format("woff");
  font-weight: bold;
font-style: italic;
font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "arial16";
  src: url("ARIALI.TTF-16-Italic.woff2") format("woff2"), url("ARIALI.TTF-16-Italic.woff") format("woff");
  font-style: italic;
font-display: block;
  text-rendering: geometricPrecision;
}