@import "./fonts/arial16.css";
/* @import "./fonts/arial24.css"; */
@import "./fonts/arial32.css";
/* @import "./fonts/comic16.css"; */
/* @import "./fonts/comic24.css"; */
/* @import "./fonts/comic32.css"; */
/* @import "./fonts/cour16.css"; */
/* @import "./fonts/cour24.css"; */
/* @import "./fonts/cour32.css"; */
/* @import "./fonts/lhandw16.css"; */
/* @import "./fonts/lhandw24.css"; */
@import "./fonts/lhandw32.css";
@import "./fonts/sseriff13.css";
/* @import "./fonts/sseriff17.css"; */
@import "./fonts/times16.css";
@import "./fonts/times24.css";
@import "./fonts/times32.css";

html {
	--c0: url("cursors/pointer/s1.png");
	--c1: url("cursors/pointer/s2.png");
	--c2: url("cursors/pointer/s3.png");
	--c3: url("cursors/pointer/s4.png");
	--c4: url("cursors/pointer/s5.png");
	--c5: url("cursors/pointer/s6.png");
	--c6: url("cursors/pointer/s7.png");
	cursor: url("cursors/blade.png"), auto;
	/* cursor: url("members/dissonatorsmall.jpg"), auto; */
	filter: hue-rotate(14deg) sepia(60%) contrast(95%) brightness(90%) blur(0.4px);
}

body {
	-webkit-font-smoothing: none;
	-moz-osx-font-smoothing: grayscale;
	image-rendering: pixelated;
	font-family: "times16", "Times New Roman", times, serif;
	font-size: 16px;
	color: #fff;
	background: #202020;
	/* background-image: url('bg.jpg'); */
	text-align: center;
	margin: 24px;
	overflow-x: hidden;
}

main {
	margin-bottom: 24px;
}

h1 {
	font-size: 32px;
	font-family: "times32", "Times New Roman", times, serif;
	font-style: italic;
	margin: 16px 0 32px;
}

blockquote {
	border: 6px solid green;
	max-width: 640px;
	margin: 12px auto;
}

blockquote a {
	color: #06f;
}

blockquote::after {
	content: "";
	clear: both;
	display: table;
}

blockquote pre {
	font-family: courier, monospace;
	word-break: break-all;
}

blockquote img,
blockquote iframe {
	display: block;
}

blockquote table img {
	width: 100%;
}

iframe {
	width: 100%;
	height: 400px;
	border: none;
	margin: 0;
}

h2 {
	font-size: 24px;
	font-family: "times24", "Times New Roman", times, serif;
}

h3,
h4,
h5 {
	font-size: 16px;
}

a {
	/* font-style: italic; */
	font-weight: bold;
	color: blue;
	cursor: var(--pointer, url("cursors/blade-pointer.png")) 15 1, auto;
	/* cursor: url("members/dissonatorsmall.jpg"), auto; */
	text-decoration-skip-ink: none;
	&:focus {
		outline: 1px solid #69f;
	}
}
a:not([href]) {
	cursor: inherit;
	color: lightblue;
	text-decoration: line-through;
}

.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}

::selection {
	background: #0000fffe;
}

p {
	margin: 8px 0;
}

.pc {
	font-size: 13px;
	margin-top: 48px;
	font-family: "sseriff13", "MS Sans Serif", arial, sans-serif;
	color: dimgrey;
	& a {
		font-weight: normal;
		color: inherit;
	}
}

table {
	width: 100%;
	border-collapse: separate;
	box-sizing: border-box;
	border: 1px solid;
	border-spacing: 2px;
	max-width: 640px;
	margin: 24px auto;
	& img {
		display: block;
	}
}
th {
	/* background: yellow; */
	color: yellow;
}

.iframe {
	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	border: 1px inset rgb(238, 238, 238);
	margin: 1px;
	& iframe {
		position: absolute;
		image-rendering: pixelated;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* .h {
	font-size: 32px;
	font-family: "lhandw32", fancy;
	color: red;
	& h3 {
		font-size: 24px;
		font-family: "lhandw24", fancy;
	}
	& p {
		font-size: 16px;
		font-family: "lhandw16", fancy;
	}
} */

/* .modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #800;
	color: #fff;
	padding-top: 10%;
	font-family: "arial16", arial, sans-serif;
	cursor: auto !important;
	& .inner {
		padding: 20px;
		max-width: 700px;
		margin: 0 auto;
		text-align: left;
		& u {
			cursor: pointer;
		}
		& h1 {
			font-family: "arial32", arial, sans-serif;
			font-style: normal;
		}
		& .buttons {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			margin-top: 24px;
			& button {
				font: inherit;
				font-weight: bold;
				border: none;
				background: none;
				color: #fff4;
				text-transform: uppercase;
				margin: 0;
				padding: 0;
				cursor: pointer;
				& + button {
					padding: 8px 12px;
					border: 1px solid #fff;
					border-radius: 3px;
					color: #fff;
				}
			}
		}
	}
} */
