@font-face {
  font-family: "arial32";
  src: url("ARIAL.TTF-32-Regular.woff2") format("woff2"), url("ARIAL.TTF-32-Regular.woff") format("woff");
  font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "arial32";
  src: url("ARIALBD.TTF-32-Bold.woff2") format("woff2"), url("ARIALBD.TTF-32-Bold.woff") format("woff");
  font-weight: bold;
font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "arial32";
  src: url("ARIALBI.TTF-32-BoldItalic.woff2") format("woff2"), url("ARIALBI.TTF-32-BoldItalic.woff") format("woff");
  font-weight: bold;
font-style: italic;
font-display: block;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "arial32";
  src: url("ARIALI.TTF-32-Italic.woff2") format("woff2"), url("ARIALI.TTF-32-Italic.woff") format("woff");
  font-style: italic;
font-display: block;
  text-rendering: geometricPrecision;
}